import * as React from 'react'
import BaseLayout from '../components/base-layout'

const PageNotFound = () => {
    return (
        <BaseLayout pageTitle="404">
            <h1 class="display-1" style={{margin:"auto"}}>Page not found!</h1>
        </BaseLayout>
    )
}

export default PageNotFound